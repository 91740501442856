<template>
  <div>
    <label for=""
      >url: <input style="width: 280px" type="text" v-model="url"
    /></label>
    <label for=""
      >method: <input style="width: 280px" type="text" v-model="method"
    /></label>
    <label for=""
      >params: <input style="width: 280px" type="text" v-model="params" />(for
      get方法)</label
    >
    <label for=""
      >body: <input style="width: 280px" type="text" v-model="body" />(for
      post方法)</label
    >

    <button @click="handleSendReq">发送</button>

    <p>响应：{{ res }}</p>
  </div>
</template>

<script>
import http from "@/http";
export default {
  name: "",
  data() {
    return {
      res: "",
      url: "",
      method: "",
      params: "",
      body: "",
    };
  },
  computed: {},
  methods: {
    handleSendReq() {
      http({
        url: this.url,
        method: this.method,
        data: JSON.parse(this.body || "{}"),
        params: JSON.parse(this.params || "{}"),
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>

import axios from 'axios';

const BaseUrl: string = process.env.BASE_URL || '';

const http = axios.create({
  baseURL: BaseUrl,
  timeout: 30 * 1000,
  headers: {},
  withCredentials: true, // default
});

http.interceptors.request.use(function(config: any) {
  return config;
});

http.interceptors.response.use(function(config: any) {
  return config.data;
});

export default http;
